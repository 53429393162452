import * as React from 'react'
import { Link } from 'gatsby'

import {
    tagpill,
  } from './tag.module.css'

const Tag = ({ label }) => {
    const href = `/archive?tag=${encodeURIComponent(label)}`
    return (
        <Link to={href} className={tagpill}>
            {label}
        </Link>
    )
}

export default Tag