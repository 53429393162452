const processTags = {
    process: function(tags) {
        if (!tags) return null
        let arr = tags.split(',')
        let array = []
        arr.forEach((item) => {
            if (item.indexOf('Film') !== -1 || item.indexOf('Culture') !== -1 || item.length === 0) return
            return array.push(item.trim())
        })
        let processed = [...new Set(array)]
        processed.sort()
        return processed
    }
};

export default processTags;