import React from 'react';

import { FaStar } from "@react-icons/all-files/fa/FaStar";

const Rating = ({rating, range}) => {

    let ratingRange = [...Array(range).keys()];

    return (
        <div className='flex justify-center pt-4 md:pt-0 md:pb-4 whitespace-nowrap'>
           {
            ratingRange.map((i) => (
                <FaStar size="2rem" key={i} className={i <= rating - 1 ? 'text-warning': 'text-gray-400 opacity-80'} />
            ))
           } 
        </div>
    )
}

Rating.defaultProps = {
    rating: 0,
    range: 5
}

export default Rating