import * as React from 'react'
import Tag from './tag'

const Tags = ({ tags, nowrap }) => {

    let styles   = 'flex justify-center flex-wrap space-x-2 mb-2'
    if (nowrap) styles = 'flex max-w-fit space-x-2 mb-2 overflow-x-auto'

    return (
        (
            tags ? 
            <div className={styles}>
                    {
                        tags.map((t) => (
                            <Tag label={t} key={t}></Tag>
                        ))
                    }
            </div> : null
        ) 
    )
}

export default Tags