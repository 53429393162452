import React, { useEffect, useState, useRef } from 'react'

import { FaTrash } from "@react-icons/all-files/fa/FaTrash"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ArticleSlug from "../components/articleSlug"
import Alert from "../components/alert"

import {
    btn
  } from '../components/button.module.css'

  import {
    pagetitle,
    pagecontent
  } from '../components/layout.module.css'

const Bookmarks = ( {data} ) => {
    const 
    title = 'Bookmarks',
    ref = useRef(),
    headingNoPosts = `You have no saved posts`,
    messageNoPosts = `To save a post to this page, search through the <strong>Archive</strong> and click on the <em>bookmark</em> icon`

    const [faves, setFaves] = useState([]);

    useEffect(() => {
        const updateStorage = () => {
            setFaves(JSON.parse(localStorage.getItem("popcornfaves"))) 
        }
        const getFaves = () => {
            const str = localStorage.getItem("popcornfaves")
            if (str && str.length > 4) setFaves(JSON.parse(str))
            window.addEventListener('storage', updateStorage)
        }
        getFaves()
        return () => {
            window.removeEventListener('storage', updateStorage)
        }
    }, []) 

    const clearStorage = () => {
        localStorage.clear()
        setFaves([])
    }

    return (
        <Layout>
            <Seo title={title} />
            <div className={pagecontent}>
                <header className='my-8'>
                    <h1 className={pagetitle}>{title}</h1>
                </header>
                
                {faves.length ? 
                    <p className='flex justify-center items-center mb-4'>You have <strong className='mx-2'>{faves.length}</strong> saved posts 
                        <button onClick={clearStorage} className={btn} title='Remove All?' aria-label='Remove all from bookmarks'>
                            <FaTrash size="36px" className='text-warning hover:text-danger ml-4'/>
                        </button>
                    </p> 
                    : 
                    <div className='flex justify-center mb-40'>
                        <Alert heading={headingNoPosts} message={messageNoPosts} type={'info'}></Alert>
                    </div>}
                
                <div className="mx-auto max-w-full min-h-full mb-8" ref={ref}>
                {
                    faves
                    .map((post) => (
                        <ArticleSlug post={post} key={post.id}></ArticleSlug>
                    ))
                }
                </div>
            </div>
        </Layout>

    )

}

export default Bookmarks