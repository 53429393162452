import React, { useEffect, useRef } from 'react';
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { FaBookmark } from "@react-icons/all-files/fa/FaBookmark"

import {
    btn
  } from './button.module.css'

const Favebutton = ({post, size}) => {

    const faveBtn = useRef()
    let faves = useRef()
    let title
    let id = post.id
    let btnstyle = `${btn} "text-gray-500"`

    const shareEvent = (category, url) => {
        trackCustomEvent({
          category: category,
          action: "Click",
          label: url,
        })
    }

    const toggleClass = (arg) => {
        if (arg) {
            faveBtn.current.classList.add("text-primary")
            faveBtn.current.classList.remove("text-gray-500")
            title = 'Remove from Bookmarks?'
        } else {
            faveBtn.current.classList.add("text-gray-500")
            faveBtn.current.classList.remove("text-primary")
            title = 'Add to Bookmarks?'
        }
        faveBtn.current.setAttribute('title', title)
    }
    

    useEffect(() => {
        const str = localStorage.getItem("popcornfaves")
        if (str && str.length > 4) {
            faves.current = JSON.parse(str)
            let isfave = faves.current.find(e => e.id === id)
            if (isfave) toggleClass(true)
            else toggleClass(false)
        } else {
            toggleClass(false)
            faves.current = []
            localStorage.setItem("popcornfaves", JSON.stringify(faves.current))
        }
    }) 

    const toggleFaves = () => {
        const str = localStorage.getItem("popcornfaves")
        faves.current = JSON.parse(str)
        const addToFaves = () => {
            let obj = {
                id: id,
                timeToRead: post.timeToRead,
                frontmatter: post.frontmatter,
                savedAt: new Date()
            }
            faves.current.unshift(obj)
            const jsonArr = JSON.stringify(faves.current)
            localStorage.setItem("popcornfaves", jsonArr)
            toggleClass(true)
            shareEvent('Bookmark', post.frontmatter.title)
        }    
        if (faves.current?.length) {
            let isfave = faves.current.find(e => e.id === id);
            if (isfave) {
                faves.current = faves.current.filter(el => el.id !== id)
                const jsonArr = JSON.stringify(faves.current)
                localStorage.setItem("popcornfaves", jsonArr)
                toggleClass(false)
            } else {
                addToFaves()
            }
        } else {
            addToFaves()
        }
        window.dispatchEvent(new Event("storage"))
    }

    return (
        <div className='flex'>
            <button data-id='favebtn' className={btnstyle} onClick={toggleFaves} ref={faveBtn} aria-label='Add/remove to bookmarks'>
                <FaBookmark size={size} />
            </button>    
         </div>
    )
}

export default Favebutton