import React from 'react';

import {
    alert,
    title,
  } from './alert.module.css'

const Alert = ({heading, message, type}) => {

    if(!type) type = Alert.defaultProps.type
    
    let stylesBlockquote = `${alert} ${type}`
    let stylesTitle = `${title} text-${type}`

    return (
        <blockquote className={stylesBlockquote}>
                        <h4 className={stylesTitle}>
                            {heading}
                        </h4>
                        <p dangerouslySetInnerHTML={{ __html: message }}></p>
        </blockquote>
    )
}

Alert.defaultProps = {
    heading: `Information`,
    message: ``,
    type: `info`
}

export default Alert